import API from "./Config";
import ENDPOINTS from "./Enpoints";
import { Client } from "@microsoft/microsoft-graph-client";
const error = "error throw by the authentication handler";

const VehiculosService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        console.log(limit)
        API.get("vehicles?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    get: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.VEHICULOS+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateNewCars: (data) => new Promise((resolve, reject) => {
        API.post("/quotes/q/carsml", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getCarByML: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.QUOTES+"q/update-prices-id/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    deleteCar: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.QUOTES+"q/delete-id/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateCarML: (data) => new Promise((resolve, reject) => {
        API.post("/quotes/q/updatecarsml", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getIdsML: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.CARS+"ids")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateCarsML: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.CARS+"search/")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getDescription: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.CARS+"description")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    savePhotos: (data, image, token) => new Promise((resolve, reject) => {
        const client = Client.init({
            defaultVersion: "v1.0",
            debugLogging: true,
            authProvider: (done) => {
                done(error, token);
            },
        })
        client
            .api(`/drives/b!7itdIxkfNkiu_jyIkoHY0c5NBOCzmFhOnjV2-JN-s_3Mr4_AMhfcRoxIibb7Cjrb/root:/General/Autos/${data.folder}/${image.name}:/content`)
            .putStream(image)
            .then(res => resolve(res))
            .catch(err => reject(err));
    }),
    createFolder: (name)=> new Promise((resolve, reject) => {
        API.get(ENDPOINTS.S3+name)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    uploadPhoto: (data, plate) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.S3+plate, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    uploadPhotoDriver: (data, folder) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.S3+'back-end/'+folder, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    saveImagens: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.VEHICULOS+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    deletePhoto: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.S3+"/d/delete", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    changeSeparate: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CARS+"separate", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    changePublicado: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CARS+"publicado", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    changeIsTriplo: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CARS+"istriplo", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    changeStatus: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CARS+"cstatus", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    changeSale: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CARS+"sale", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    savePlate: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.CARS+"plate", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getActives: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.VEHICULOS+"v/disponibles")
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    saveData: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.VEHICULOS+"edit/"+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    createData: (data, id) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.VEHICULOS+"create/", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    createDataNew: (data, id) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.VEHICULOS+"create-new/", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateField: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.VEHICULOS+"u/update-field/", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getArchivos: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.VEHICULOS+"u/get-archivos/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    setArchivos: (data, id) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.VEHICULOS+"u/set-archivos/"+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
}

export default VehiculosService;