import React ,{ useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { Form, Button, Col, Input, Modal, Select } from 'antd';
import VehiculosService from 'src/services/VehiculosService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import RetomasService from 'src/services/Retoma';
import ImageUploading from 'react-images-uploading';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AutoComplete, InputNumber, Checkbox  } from 'antd';
import MakeService from 'src/services/MakeService';
import YearService from 'src/services/YearService';
import ModelService from 'src/services/ModelService';
import VersionService from 'src/services/VersionService';
import QuoteService from 'src/services/QuoteService';
import retoma from '../../../src/assets/images/retoma.jpeg';
const { TextArea } = Input;

const CrearRetoma = () => {
    const [vehicles, setVehicles] = useState([])
    const [vehiclesList, setVehiclesList] = useState([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [form] = Form.useForm();
    const [formCalc] = Form.useForm();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]);
    const [carSelected, setCarSelected] = useState();
    const [showLot, setShowLot] = useState(false);
    const [showLot2, setShowLot2] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [marcas, setMarcas] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [modelosList, setModelosList] = useState([]);
    const [anhos, setAnhos] = useState([]);
    const [versiones, setVersiones] = useState([]);
    const [versionesList, setVersionesList] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [linkCarroLabel, setLinkCarroLabel] = useState("Link referencia tucarro")
    const [linkTucarro, setLinkTucarro] = useState("#");
    const [statusNoVehiculo, setStatusNoVehiculo] = useState(true)
    const estados = [
        {value: 'Excelente', label: 'Excelente'},
        {value: 'Muy Bueno', label: 'Muy Bueno'},
        {value: 'Bueno', label: 'Bueno'},
        {value: 'Semi Bueno', label: 'Semi Bueno'},
        {value: 'Regular', label: 'Regular'}
    ];

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getVehicles();
        getData();
    }, [])

    const getData = () => {
        MakeService.getAll().then(resp =>{
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.id, label: element.name })
            });
            setMarcas(items);
        })
    }

    const changeMarca = () =>{
        YearService.getYearByMake(formCalc.getFieldValue('marca')).then(resp =>{
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.id, label: element.name })
            });
            setAnhos(items)
        })
    }
    const changeAnho = () =>{
        ModelService.getModelsByYear(formCalc.getFieldValue('anho')).then(resp =>{
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.id, label: element.name })
            });
            setModelosList(resp.data)
            setModelos(items)
        })
    }
    const changeModel = () =>{
        VersionService.getVersionsByModel(formCalc.getFieldValue('modelo')).then(resp =>{
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.id, label: element.name })
            });
            setVersionesList(resp.data)
            setVersiones(items)
        })
    }
    const showModal = () =>{
        setIsModalOpen(true);
    }
    const handleOk = () => {
        formCalc.submit();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getVehicles = () =>{
        VehiculosService.getActives().then(resp =>{
            setVehicles(resp.data)
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.title+" - "+element.plate, label: element.title+" - "+element.plate })
            });
            setVehiclesList(items);
        })
    }

    const changeVehicle = (value) =>{
        if(value.length > 0){
            value = value[0]
            let plate = value.substr(value.length - 6);
            let car = vehicles.filter(x => x.plate === plate);
            if(car.length > 0) car = car[0]
            setCarSelected(value)
            form.setFieldsValue({
                prec_ref_veh_venta: car ? car.price : 0,
            });
        }
    }

    const changeNeto = () =>{
        if(!form.getFieldValue('prec_ref_veh_venta')){
            form.setFieldsValue({
                prec_ref_veh_venta: 0
            })
        }
        let _diferencia_neta = parseInt(form.getFieldValue('prec_ref_veh_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        form.setFieldsValue({
            diferencia_neta: _diferencia_neta,
            prec_ref_veh_retomar: parseInt(form.getFieldValue('prec_ref_veh_retomar'))
        });
    }

    const changeVenta = () =>{
        if(!form.getFieldValue('prec_ref_veh_venta')){
            form.setFieldsValue({
                prec_ref_veh_venta: 0
            })
        }
        let _diferencia_neta = parseInt(form.getFieldValue('prec_ref_veh_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        form.setFieldsValue({
            diferencia_neta: _diferencia_neta,
            prec_ref_veh_venta: parseInt(form.getFieldValue('prec_ref_veh_venta'))
        });
    }

    const changePEVenta = () =>{
        let utilidad_esperada = parseInt(form.getFieldValue('precio_estimado_venta')) - parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        let rentabilidad = utilidad_esperada / parseInt(form.getFieldValue('prec_ref_veh_retomar'));
        form.setFieldsValue({
            utilidad_esperada: utilidad_esperada,
            rentabilidad: rentabilidad.toFixed(2) * 100
        });
    }
    const onFinishF = (values) =>{
        console.log(values)
    }
    const onFinish = (values) =>{
        let neto = values.prec_ref_veh_venta - values.prec_ref_veh_retomar;
        let data = {
            vehiculo_venta: carSelected,
            vehiculo_retoma: values.vehiculo_retoma,
            estado: 'por aprobar',
            diferencia_neta: neto,
            km_vehiculo_retoma: values.km_vehiculo_retomar,
            placa_vehiculo_retomar: values.placa_vehiculo_retomar,
            promedio_tiempo_venta: values.prom_tiempo_venta,
            precio_ref_veh_retoma: values.prec_ref_veh_retomar,
            precio_ref_veh_venta: values.prec_ref_veh_venta,
            link_tucarro_referencia: values.link_ref_tucarro,
            observaciones: values.observaciones ? values.observaciones : '' ,
            user_id: authUser().id,
            plate: '',
            title: carSelected,
            precio_estimado_venta: values.precio_estimado_venta, 
            utilidad_esperada: values.utilidad_esperada, 
            rentabilidad: values.rentabilidad,
            categoria: categoria
        };
        setDisabledButton(false)
        setShowLot(true)
        let images = [];
        RetomasService.save(data).then(resp=>{
            let id = resp.data;
            values.fotos.forEach( async (element, index) => {
                const data = new FormData() 
                data.append('file', element.file); 
                await VehiculosService.uploadPhoto(data, "retoma_"+id).then(async response =>{
                    let _imagen = {
                        retoma_id: id, 
                        foto: response.uri
                    }
                    images.push(_imagen)                    
                })
                if((index + 1) == values.fotos.length){
                    images.forEach(element => {
                        RetomasService.saveImagens(element).then(response2 =>{ 
                            console.log("Imagen subida...")
                        })
                    });    
                    toast.success('Retoma creada con éxito', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });            
                    setShowLot(false)    
                    setTimeout(() => {                          
                       navigate('/retomas');
                    }, 2000);
                }
            })
        })
    }

    const saveCalculadora = (values) =>{
        let data = {
            model: modelos.find(x => x.value === parseInt(values.modelo)).label,
            make: marcas.find(x => x.value ===  parseInt(values.marca)).label,
            version: versiones.find(x => x.value ===  parseInt(values.version)).label, 
            year: anhos.find(x => x.value ===  parseInt(values.anho)).label,
            version_id: parseInt(values.version),
            km: parseInt(values.km),
            status: values.estado
        }

        let _make = data.make.replaceAll(' ', '-');
        let _url = "https://carros.tucarro.com.co/"+_make.toLowerCase()+"/"+modelosList.find(x => x.id === parseInt(values.modelo)).sname.toLowerCase()+"/"+data.year+"_OrderId_PRICE_ITEM*CONDITION_2230581_NoIndex_True";
        let _version = versionesList.find(x => x.id ===  parseInt(data.version_id))
        let _data = {
            "url": _url,
            "category":  _version.category,
            "status": data.status,
            "keys":  _version.key,
            "km": data.km,
            "year": data.year,
            "make": _make
        }
        setShowLot2(true)
        setLinkTucarro(_url)
        QuoteService.getTuCarro(_data).then(resp =>{
            form.setFieldsValue({
                link_ref_tucarro: _url,
                prec_ref_veh_retomar: resp.respuesta.offer_price,
                vehiculo_retoma: data.make+" "+data.model+" "+data.version+" "+data.year,
                km_vehiculo_retomar: data.km
            })
            setLinkCarroLabel("Link referencia tucarro")
            setCategoria(_version.category);
            setIsModalOpen(false);
            formCalc.resetFields();
            setShowLot2(false)
        })
    }
    const changeLink = (e) =>{
        setLinkTucarro(e.target.value)
    }
    const changeNoVehiculo = (e) => {
        setStatusNoVehiculo(e.target.checked)
    }
    
    return(
        <>
            <ToastContainer />
            <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" />
            </div>
            <Modal title="Calculadora de precios" okText="Calcular" okType='primary' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={700}>
                <div className={ showLot2 ? 'showL' : 'no-showL'} >
                    <Lottie animationData={gifJson} loop={true} className="loadLottie" style={{ zIndex: 1}}/>
                </div>
                <Form name="calculadora-form" form={formCalc} onFinish={saveCalculadora} layout='vertical'>
                    <CRow>
                        <Col md={12} xs={24}>
                            <Form.Item label="Seleccione la marca" name="marca" 
                                rules={[
                                    {
                                        required: true,
                                        message: 'La marca es requerida!',
                                    },
                                ]}>
                                <Select                                
                                options={marcas} 
                                onSelect={ changeMarca }
                                notFoundContent={'Sin resultados'}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }>                                    
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Seleccione el año" name="anho" onChange={ changeAnho }
                                rules={[
                                    {
                                        required: true,
                                        message: 'El año es requerido!',
                                    },
                                ]}>
                                <Select 
                                    options={anhos}
                                    onSelect={ changeAnho } 
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }></Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Seleccione el modelo" name="modelo" onChange={ changeModel }
                                rules={[
                                    {
                                        required: true,
                                        message: 'El modelo es requerido!',
                                    },
                                ]}>
                                <Select 
                                    options={modelos} 
                                    onSelect={ changeModel }
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                ></Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Seleccione versión" name="version"
                                rules={[
                                    {
                                        required: true,
                                        message: 'La versión es requerido!',
                                    },
                                ]}>
                                <Select 
                                    options={versiones}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                ></Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Kilometraje" name="km"
                                rules={[
                                    {
                                        required: true,
                                        message: 'El kilometraje es requerido!',
                                    },
                                ]}>
                                <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: '100%' }} placeholder='Kilometraje'
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Estado del vehículo" name="estado"
                                rules={[
                                    {
                                        required: true,
                                        message: 'El estado es requerido!',
                                    },
                                ]}>
                                <Select options={ estados }></Select>
                            </Form.Item>
                        </Col>
                    </CRow>
                </Form>
            </Modal>
            <CRow>
                <CCol xs={12} className='text-center mb-2'>
                    <h2>Nueva retoma</h2>
                </CCol>
                <CCol xs={12} className='text-center mb-2'>
                    <img src={retoma} alt="Retomas" />
                </CCol>
            </CRow>
            <Form name="basic" form={form} onFinishFailed={onFinishF} onFinish={onFinish} layout='vertical'>
                <CRow className='mb-2'>
                    <Col md={12} xs={24}>
                        <Form.Item label="Vehículo en venta" name="vehiculo_venta" style={{ marginBottom: 0}}>
                            {/* <AutoComplete
                                options={vehiclesList}
                                filterOption={true}
                                onSelect={changeVehicle}
                                placeholder="Vehiculo en venta"
                                disabled={ statusNoVehiculo }
                            /> */}
                            <Select
                                showSearch
                                placeholder="Seleccione un vehiculo"
                                optionFilterProp="label"
                                onChange={ changeVehicle }
                                options={ vehiclesList }
                                mode="tags"
                                maxCount={1}
                                disabled={ statusNoVehiculo }
                            />
                        </Form.Item>
                        {/* <input type='checkbox' name="no_vehiculo" onChange={ changeNoVehiculo } checked/> */}
                        <Checkbox checked={statusNoVehiculo} onChange={changeNoVehiculo}>
                            Desmarque para buscar vehículo
                        </Checkbox>
                    </Col>
                    <Col md={9} xs={21} >                        
                        <Form.Item label="Vehículo a retomar"  name="vehiculo_retoma"
                            rules={[
                                {
                                    required: true,
                                    message: 'El vehiculo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Vehiculo a retomar' />
                        </Form.Item>                        
                    </Col>
                    <Col md={3} xs={3} className='text-end'>
                        <Button type="primary" onClick={showModal} style={{ marginTop: 29}}> Calculadora </Button>
                    </Col>
                </CRow>
                <CRow>
                    <Col md={8} xs={24} >
                        <Form.Item label="Precio referencia de vehículo en venta"  onBlur={ changeVenta } name="prec_ref_veh_venta">
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Precio referencia de vehículo en venta'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                disabled={ statusNoVehiculo }
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24} >
                        <Form.Item label="KM vehículo a retomar"  name="km_vehiculo_retomar"
                            rules={[
                                {
                                    required: true,
                                    message: 'El Kilometraje es requerido!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24} >
                        <Form.Item label="Placa vehículo a retomar"  name="placa_vehiculo_retomar"
                            rules={[
                                {
                                    required: true,
                                    message: 'La placa es requerida!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: AAA111' /> 
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >
                        <Form.Item label="Promedio de tiempo en venta"  name="prom_tiempo_venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Promedio de tiempo en venta!',
                                }
                            ]}>
                            <Input type='text' placeholder='Promedio de tiempo en venta' />                            
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24} >
                        <Form.Item label="Precio referencia de vehículo a retomar" onBlur={ changeNeto } name="prec_ref_veh_retomar"
                            rules={[
                                {
                                    required: true,
                                    message: 'Precio referencia de vehículo a retomar!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Precio referencia de vehículo a retomar'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>                    
                </CRow>
                <CRow>
                    <Col md={12} xs={24} >
                        <Form.Item label="Diferencia neta"  name="diferencia_neta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Diferencia neta!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Diferencia neta'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')} disabled
                            />
                        </Form.Item>
                    </Col>  
                    <Col md={12} xs={24} >
                        <Form.Item label={linkCarroLabel}  name="link_ref_tucarro" style={{ marginBottom: 0}} onChange={ changeLink }
                            rules={[
                                {
                                    required: true,
                                    message: 'Link referencia tucarro!',
                                }
                            ]}>
                            <Input type='text' placeholder='Link referencia tucarro' />
                        </Form.Item>
                        { linkTucarro !== '#' ? <a href={linkTucarro} target='_blank'>Ver referencias</a> : null }
                    </Col>
                                      
                </CRow>
                <CRow>
                    <Col md={8} xs={24}>
                        <Form.Item label="Precio estimado de venta"  onBlur={ changePEVenta } name="precio_estimado_venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Precio estimado de venta!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Precio estimado de venta'
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Utilidad esperada"  name="utilidad_esperada"
                            rules={[
                                {
                                    required: true,
                                    message: 'Utilidad esperada!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: '100%' }}
                                placeholder='Utilidad esperada' disabled
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Rentabilidad"  onBlur={ changeVenta } name="rentabilidad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Rentabilidad!',
                                }
                            ]}>
                            <InputNumber
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace('%', '')}
                                style={{ width: '100%' }}
                                placeholder='Rentabilidad' disabled
                            />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col md={24} xs={24} >
                        <Form.Item label="Observaciones"  name="observaciones">
                            <TextArea rows={4}  placeholder='Observaciones' />
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow>
                    <Col xs={24} span={24} id='drag_ini'>
                        <Form.Item label="Fotos del vehiculo a retomar"  name="fotos"
                            rules={[
                                {
                                    required: true,
                                    message: 'Las fotos son requeridas!',
                                }
                            ]}>
                            <ImageUploading
                                name="fotos"
                                multiple
                                value={fileList}
                                dataURLKey="data_url">
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button type='button'
                                            style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            className="btn_upload"
                                            {...dragProps} >
                                            Haga clic o Suelte aquí
                                        </button>
                                        &nbsp;
                                        <button className='btn_dd' onClick={onImageRemoveAll} disabled={ imageList.length === 0}>Remover todas las imágenes</button>
                                        <div className='imgList'>
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <img src={image['data_url']} alt="" width="100" />
                                                    <div className="image-item__btn-wrapper">
                                                        <button className='btn_dd' onClick={() => onImageUpdate(index)}>Actualizar</button>
                                                        <button className='btn_dd' onClick={() => onImageRemove(index)}>Remover</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </ImageUploading> 
                        </Form.Item>
                    </Col>
                </CRow>
                <CRow className='mt-4 mb-4'>
                    <Col span={24}  md={24} xs={24} className='text-center'>
                        <Form.Item>
                            <Button type='primary'  htmlType="submit" disabled={disabledButton}>Guardar retoma</Button>
                        </Form.Item>
                    </Col>
                </CRow>
            </Form>
        </>
    )
}

export default CrearRetoma;