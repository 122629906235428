import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Input, Row, Select, Modal } from 'antd';
import { CContainer, CRow } from '@coreui/react';
import TextArea from 'antd/es/input/TextArea';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import { useNavigate } from "react-router-dom";
import DriverService from 'src/services/Driver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthUser } from 'react-auth-kit';

const transportationOptions = [
  { value: 'A pie', label: 'A pie' },
  { value: 'Motocicleta', label: 'Motocicleta' },
  { value: 'Vehículo', label: 'Vehículo' },
];

const Create = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();

  useEffect(() => {
    if (!authUser()?.id) {
      navigate('/');
    }
  }, [authUser, navigate]);

  const handleSubmit = async (values) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoading(true);

    const data = {
      kilometraje: values.km,
      placa: values.placa,
      transporte: values.transporte || 'Ninguno',
      costo: values.costo || 0,
      detalles: values.detalles || 'Ninguno',
      estado: 'En ruta',
      direccion: values.direccion_recepcion,
      destino: values.direccion_destino,
      user_id: authUser().id,
    };

    try {
      const response = await DriverService.save(data);
      toast.success('Traslado creado con éxito', {
        position: "top-right",
        autoClose: 2000,
        theme: "light",
      });

      setTimeout(() => {
        setLoading(false);
        navigate(`/conductor/solicitud/fotos/${response.id}`);
      }, 3000);
    } catch (error) {
      toast.error('Error al crear el traslado. Inténtelo de nuevo.', {
        position: "top-right",
        autoClose: 2000,
        theme: "light",
      });
      setLoading(false);
    }
  };

  return (
    <CContainer>
      <ToastContainer />
      {loading && (
        <div className="fondoLottie">
          <Lottie animationData={gifJson} loop className="loadLottie" />
        </div>
      )}
      <Row>
        <Col span={24} className="text-center my-4">
          <h1>Traslado</h1>
        </Col>
      </Row>
      <Form
        name="create-traslado"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <CRow>
          <Col md={12} xs={24}>
            <Form.Item
              label="Placa"
              name="placa"
              rules={[
                { required: true, message: 'La placa es requerida!' },
                { len: 6, message: 'La placa debe contener 6 caracteres' },
              ]}
            >
              <Input placeholder="Placa del vehículo" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="Kilometraje"
              name="km"
              rules={[{ required: true, message: 'El kilometraje es requerido!' }]}
            >
              <Input type="number" placeholder="Kilometraje del vehículo" />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col md={12} xs={24}>
            <Form.Item label="Medio de transporte" name="transporte">
              <Select placeholder="Seleccione una opción" options={transportationOptions} />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Costo de transporte" name="costo">
              <Input placeholder="Ingrese el costo" />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col md={24} xs={24}>
            <Form.Item
              label="Dirección de recepción"
              name="direccion_recepcion"
              rules={[
                { required: true, message: 'La dirección de recepción es requerida!' },
                { min: 6, message: 'La dirección ingresada no es válida' },
              ]}
            >
              <TextArea placeholder="¿Dónde está recibiendo el vehículo?" />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col md={24} xs={24}>
            <Form.Item
              label="Dirección de destino"
              name="direccion_destino"
              rules={[
                { required: true, message: 'La dirección de destino es requerida!' },
                { min: 6, message: 'La dirección ingresada no es válida' },
              ]}
            >
              <TextArea placeholder="¿Dónde va a entregar el vehículo?" />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col md={24} xs={24}>
            <Form.Item label="Detalles del vehículo" name="detalles">
              <TextArea />
            </Form.Item>
          </Col>
        </CRow>
        <CRow>
          <Col span={24} className="text-center">
            <Button type="primary" htmlType="submit" disabled={loading}>
              Guardar traslado
            </Button>
          </Col>
        </CRow>
      </Form>
    </CContainer>
  );
};

export default Create;