import React, { useState, useEffect } from 'react';
import { InboxOutlined, ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, message, Upload, Card, Form, Button } from 'antd';
import { useParams } from "react-router-dom";
import DriverService from 'src/services/Driver';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";

const { Dragger } = Upload;

const Fotos = () => {
  const { id } = useParams();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [traslado, setTraslado] = useState({})
  const [fotosIniciales, setFotosIniciales] = useState([])
  const [fotosFinales, setFotosFinales] = useState([])
  const [direcciones, setDirecciones] = useState([])
  const [direccionIni, setDireccionIni] = useState([])
  const [direccionEnd, setDireccionEnd] = useState([])
  const [disabledButton, setDisabledButton] = useState(false);
  const [vehicleFoto, setVehicleFoto] = useState([]);

  const [index, setIndex] = useState(-1);
  var currentImage = vehicleFoto[index];
  const nextIndex = (index + 1) % vehicleFoto.length;
  const nextImage = vehicleFoto[nextIndex] || currentImage;
  const prevIndex = (index + vehicleFoto.length - 1) % vehicleFoto.length;
  const prevImage = vehicleFoto[prevIndex] || currentImage;

  const [vehicleFotoF, setVehicleFotoF] = useState([]);

  const [indexF, setIndexF] = useState(-1);
  var currentImageF = vehicleFotoF[indexF];
  const nextIndexF = (indexF + 1) % vehicleFotoF.length;
  const nextImageF = vehicleFotoF[nextIndexF] || currentImageF;
  const prevIndexF = (indexF + vehicleFotoF.length - 1) % vehicleFotoF.length;
  const prevImageF = vehicleFotoF[prevIndexF] || currentImageF;

  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const handleCloseF = () => setIndexF(-1);
  const handleMovePrevF = () => setIndexF(prevIndexF);
  const handleMoveNextF = () => setIndexF(nextIndexF);

  useEffect(() => {
    if(!authUser().id){
        navigate('/')
    }
    getItem();
  }, []);

  const goBack = () =>{
    navigate('/conductor')
  }

  const handleClick = (index) => {
    currentImage = vehicleFoto[index]
    setIndex(index);
  }

  const handleClickF = (index) => {
    currentImageF = vehicleFotoF[index]
    setIndexF(index);
  }

  const deleteImg = async (currentImage) => {
    try {
      // Eliminar la foto utilizando el servicio
      await DriverService.deletePhoto(currentImage.id);
  
      // Mostrar mensaje de éxito
      toast.success('Foto eliminada con éxito', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
  
      // Actualizar la lista de fotos sin recargar la página
      getItem();
    } catch (error) {
      console.error("Error al eliminar la foto:", error);
      toast.error('Error al eliminar la foto. Intenta nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

    const propsIniciales = {
      name: "file",
      multiple: true,
      action: `https://back.triplo.com.co/api/v1/uploads3/back-end/driver_${id}`,
      onChange: async (info) => {
        const { status, response, name } = info.file;
    
        if (status === "done") {
          try {
            if (!response?.uri) {
              throw new Error("El servidor no devolvió una URL válida.");
            }
    
            // Crear objeto de foto
            const photo = {
              name: `IMG_INI_${id}_${name}`,
              url: response.uri,
              category: "ini",
              vehicle_driver_id: id,
            };
    
            // Guardar foto en el servidor
            await DriverService.savePhoto(photo);
            message.success(`${name} foto agregada con éxito.`);
            
            // Actualizar datos (solo una vez después de todas las fotos)
            getItem();
          } catch (error) {
            console.error("Error al guardar la foto:", error);
            message.error(`Hubo un error al guardar la foto ${name}.`);
          }
        } else if (status === "error") {
          message.error(`${name} falló al cargar la foto.`);
        }
      },
      onDrop: (e) => {
        console.log("Dropped files", e.dataTransfer.files);
      },
    };
    const propsFinales = {
      name: "file",
      multiple: true,
      action: `https://back.triplo.com.co/api/v1/uploads3/back-end/driver_${id}`,
      onChange: async (info) => {
        const { status, response, name } = info.file;
    
        if (status === "done") {
          try {
            if (!response?.uri) {
              throw new Error("El servidor no devolvió una URL válida.");
            }
    
            // Crear objeto de foto
            const photo = {
              name: `IMG_END_${id}_${name}`,
              url: response.uri,
              category: "end",
              vehicle_driver_id: id,
            };
    
            // Guardar foto en el servidor
            await DriverService.savePhoto(photo);
            message.success(`${name} foto agregada con éxito.`);
            
            // Actualizar datos (solo una vez después de todas las fotos)
            getItem();
          } catch (error) {
            console.error("Error al guardar la foto:", error);
            message.error(`Hubo un error al guardar la foto ${name}.`);
          }
        } else if (status === "error") {
          message.error(`${name} falló al cargar la foto.`);
        }
      },
      onDrop: (e) => {
        console.log("Dropped files", e.dataTransfer.files);
      },
    };
    const getItem = async () =>{
      try {
        const data = await DriverService.get(id);
    
        const { traslado, direcciones, photosIni, photosEnd } = data;
    
        setTraslado(traslado);
        setDirecciones(direcciones);
    
        // Dirección inicial y final
        setDireccionIni(direcciones[0]?.direccion || "");
        setDireccionEnd(direcciones[direcciones.length - 1]?.direccion || "");
    
        // Mapear fotos iniciales y finales
        const mapPhotos = (photos) =>
          photos.map((photo) => ({
            src: photo.url,
            original: photo.url,
            width: 500,
            height: 400,
            caption: photo.url,
            id: photo.id,
          }));
    
        setFotosIniciales(photosIni);
        setVehicleFoto(mapPhotos(photosIni));
    
        setFotosFinales(photosEnd);
        setVehicleFotoF(mapPhotos(photosEnd));
      } catch (error) {
        console.error("Error fetching item data:", error);
      }
    }
    const onFinish = async (values) => {      
      if (values.direcciones?.length) {
        try {
          // Crear un array de direcciones para guardar
          const direccionesToSave = values.direcciones.map((dir) => ({
            direccion: dir,
            id_traslado: id,
          }));
    
          // Guardar todas las direcciones de manera concurrente
          await Promise.all(direccionesToSave.map(DriverService.saveDireccion));
    
          // Limpiar direcciones y actualizar datos
          values.direcciones = [];
          await getItem();
    
          // Recargar página después de un segundo
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error) {
          console.error("Error saving direcciones:", error);
        }
      }
    }
    return (
      <>
        <Row className='mb-4'>
          <Col xs={24} span={24} className='text-center'>
            <ArrowLeftOutlined className="iconBack" onClick={ goBack } title='Volver al listado'/>
            <h1>Carga de fotos</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} md={24}>
            <Card title={"Placa: "+traslado.placa}>
              <p><strong>Kilometraje:</strong> { kmFormat(traslado.km) }</p>
              <div>
                <strong>Direcciones: </strong>
                <ul>
                {
                    direcciones.length > 1 ?
                    direcciones.map((direccion, index) => (
                      <li key={"dir"+index}>{ direccion.direccion }</li>
                    ))
                    : null
                }
                </ul>
              </div>              
              <p><strong>Detalle:</strong> { traslado.detalles }</p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} md={24}>
            <Form name="basic" onFinish={onFinish} layout='vertical'>
              <Form.List name="direcciones">
              {(fields, { add, remove }, { errors }) => (
                  <>
                      {fields.map((field, index) => (
                          <Form.Item required={false} key={field.key} label="Dirección adicional">
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']} label="Dirección adicional"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Por favor agregue una dirección o borre este campo",
                              },
                              {
                                  min: 6,
                                  message: 'La dirección ingresada no es válida'
                              }
                            ]}
                            noStyle
                          >
                            <TextArea></TextArea>
                          </Form.Item>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                          <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ float: 'right' }}
                              icon={<PlusOutlined />}
                          >
                              Agregar otra dirección
                          </Button>
                          <Form.ErrorList errors={errors} />
                      </Form.Item>
                      <Form.Item>
                        <button style={{ float: 'right' }} type="submit" className='btn btn-primary ms-2' disabled={disabledButton}>Guardar dirección</button>
                      </Form.Item>
                  </>
              )}
              </Form.List>
            </Form>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col span={24} md={24} xs={24}>
          <Card
            style={{
              marginTop: 16,
            }} type="inner" title={"Fotos iniciales ("+fotosIniciales.length+")"}>
            <Row>
              
              {
                vehicleFoto ? 
                <Col md={24} xs={24} className='text-center mb-4'>
                    <Gallery
                        images={vehicleFoto}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                            toolbarButtons={[
                                <button className='btn btn-danger' type='button' onClick={ () => deleteImg(currentImage)}>Eliminar</button>
                            ]}
                        />
                    )}
                </Col> : null
                }
              <Col xs={24} span={24} id='drag_ini'>
                <Dragger {...propsIniciales}>
                  <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Arrastra las imágenes aquí o Haz clic!</p>
                  <p className="ant-upload-hint">
                    Seleccione las imágenes iniciales que desea agregar a éste traslado
                  </p>
                </Dragger>
              </Col>
            </Row> 
          </Card>
          </Col>
          <Col span={24} md={24} xs={24}>
          <Card
            style={{
              marginTop: 16,
            }} type="inner" title={"Fotos finales ("+fotosFinales.length+")"}>
              <Row>
              {
                vehicleFotoF ? 
                <Col md={24} xs={24} className='text-center mb-4'>
                    <Gallery
                        images={vehicleFotoF}
                        onClick={handleClickF}
                        enableImageSelection={false}
                    />
                    {!!currentImageF && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImageF.original}
                            imageTitle={currentImageF.caption}
                            mainSrcThumbnail={currentImageF.src}
                            nextSrc={nextImageF.original}
                            nextSrcThumbnail={nextImageF.src}
                            prevSrc={prevImageF.original}
                            prevSrcThumbnail={prevImageF.src}
                            onCloseRequest={handleCloseF}
                            onMovePrevRequest={handleMovePrevF}
                            onMoveNextRequest={handleMoveNextF}
                            toolbarButtons={[
                                <button className='btn btn-danger' type='button' onClick={ () => deleteImg(currentImageF)}>Eliminar</button>
                            ]}
                        />
                    )}
                </Col> : null
                }
                <Col xs={24} span={24}  id='drag_final'>
                  <Dragger {...propsFinales}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Arrastra las imágenes aquí o Haz clic!</p>
                    <p className="ant-upload-hint">
                      Seleccione las imágenes finales que desea agregar a éste traslado
                    </p>
                  </Dragger>
                </Col>
              </Row> 
            </Card>
          </Col>
        </Row>
      </>
        
    )
}

export default Fotos;