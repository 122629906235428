import API from "./Config";
import ENDPOINTS from "./Enpoints";

const RetomasService = {
    getAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("retoma?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    get: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.RETOMA+"/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getRetomas: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.RETOMA+"/s/all")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    delete: (id) => new Promise((resolve, reject) => {
        API.delete(ENDPOINTS.RETOMA+"/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    save: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.RETOMA, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    update: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.RETOMA+"/"+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    saveImagens: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.RETOMA+"/photo", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getOne: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.RETOMA+"/"+id)
        .then(
            res => res.data
        )
          .then(
            data => resolve(data)
          )
          .catch(
            err => reject(err)
          )
    }),
    updateOfertado: (data, id) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.RETOMA+"/c/change-estado/"+id, data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    })
}

export default RetomasService;
