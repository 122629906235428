import { CButton, CCol, CRow } from '@coreui/react';
import React ,{ useEffect, useState } from 'react'
import VehiculosService from 'src/services/VehiculosService';
import DataTable from 'react-data-table-component'
import { Puff } from 'react-loader-spinner';
import { useNavigate  } from "react-router-dom";
import MakeService from 'src/services/MakeService';
import Moment from 'moment'
import { Link  } from "react-router-dom";
import { FormOutlined, CloseCircleOutlined } from '@ant-design/icons';
import imgDefault from '../../assets/images/sinimagen.jpg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModelService from 'src/services/ModelService';
import YearService from 'src/services/YearService';

const Vehiculos = () => {
  const [data, setData] = useState([]);
  const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
  const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
  const [showLoad, setShowLoad] = useState(false);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabledYear, setDisabledYear] = useState(true);
  const [disabledModel, setDisabledModel] = useState(true);
  const [makes, setMakes] = useState([]);
  const [years, setYears] = useState([]);
  const [models, setModels] = useState([]);
  const [filters, setFilters] = useState({
    "search" : '',
    "isSale": 'all',
    "isPublished": 'all',
    "estado": "all",
    "make": "",
    "model": "",
    "year": ""
  })

  const sortByPrice = (rowA, rowB) =>{
    const a = rowA.price;
    const b = rowB.price;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  }

  const sortByKM = (rowA, rowB) =>{
    const a = parseInt(rowA.kilometers);
    const b = parseInt(rowB.kilometers);

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  }

  const sortBySaleDate = (rowA, rowB) =>{
    const a = Moment(rowA.sale_date);
    const b = Moment(rowB.sale_date);

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  }

  const statusBodyTemplate = (_car) => {
    let badge = '';
    let valor = '';
    if (_car.published === 0) {
      badge = 'danger';
      valor = 'No';
    }  else if (_car.published === 1) {
      badge = 'success';
      valor = 'Si';
    }
    return <span className={'badge rounded-pill text-bg-'+badge}>{valor}</span>;
  };

  const getImageMain = (rowData) =>{
    let _pictures = rowData.pictures;
    let img_main = imgDefault;
    if(_pictures){
      _pictures = _pictures.split(',');
      img_main = _pictures[0];
    }
    return <a href={rowData.link_ml} target='_blank'><img src={img_main} alt={rowData.title} className='img-thumbnail' width={200}/></a>
  }

  const getEstadoProceso = (_car) =>{
    let x = 0;
    if(_car.nro_abonos){
      x = (_car.nbonos * 100) / _car.nro_abonos
    }
    return (<div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow={x} aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar" style={{ width: `${x}%`}}>{x}%</div>
    </div>)
  }

  const eliminar = (id) =>{
    console.log("DELETE", id)
    VehiculosService.deleteCar(id).then(resp =>{
      window.location.reload();
    })
  }

  const columns = [
    {
      name: 'Imagen',
      selector: rowData => getImageMain(rowData),
      width: '200px'
    },    
    {
      name: 'Vehículo',
      selector: row => <Link to={"/vehiculos/ver/"+row.id}>{ row.make+" "+row.model+" "+row.year }</Link>,
      sortable: true,
      width: '250px'
    },
    {
      name: 'Precio',
      selector: row => moneyFormat(row.price),
      sortable: true,
      sortFunction: sortByPrice
    },
    {
      name: 'Placa',
      selector: row => <strong className='text-center'>{ row.plate ? row.plate : '---' }</strong>,
      sortable: true
    },
    {
      name: 'KM',
      selector: row => kmFormat(row.kilometers),
      sortable: true,
      sortFunction: sortByKM
    },
    {
      name: '¿Publicado?',
      selector: row => statusBodyTemplate(row),
      sortable: true
    },
    {
      name: '¿Vendido?',
      selector : rowData => rowData.is_sale === 1 ? (<span className="badge text-bg-success">SI</span>) : (<span className="badge text-bg-info">NO</span>),
    },
    // {
    //   name: '¿Vendido?',
    //   selector: rowData => rowData.is_sale === 1 ? (<div className="text-center"><Switch checked onChange={() => onChange(rowData.id, 0)} /><p>{ Moment(rowData.sale_date).format("DD/MM/YYYY") }</p></div>) : (<div className="text-center"><Switch onChange={() => onChange(rowData.id, 1)} /><p>---</p></div>),
    //   sortable: true,
    //   width: '120px',
    //   sortFunction: sortBySaleDate


    
    // },
    // {
    //   name: 'Estado de pagos',
    //   selector : rowData => getEstadoProceso(rowData),
    // },
    {
      name: 'Acciones',
      selector: rowData => {
        return <div className='d-flex justify-content-between'>
          <div className='text-center cc1'>
            <Link to={"/vehiculos/editar/"+rowData.id}>
              <FormOutlined className='iconCameraTable2' title='Editar'/>
            </Link>
            <Link onClick={ () => eliminar(rowData.id) }>
              <CloseCircleOutlined className='iconDeleteTable' title='Eliminar'/>
            </Link>
          </div>
        </div>
      }
    }
  ];

  
  const saveField = (event) =>{
    let _filters = filters;
    switch (event.target.name) {
      case 'selectIsSale':
        filters.isSale = event.target.value
        break;
      case 'selectIsPublished':
        filters.isPublished = event.target.value
        break;
      case 'selectEstado':
        filters.estado = event.target.value
        break;
      case 'selectMake':
        filters.make = event.target.value;
        if(event.target.value === '') setDisabledYear(!disabledYear)
        else getYears()
        break;
      case 'selectModel':
        filters.model = event.target.value
        break;
      case 'selectYear':
        filters.year = event.target.value
        if(event.target.value === '') setDisabledModel(!disabledModel)
        else getModels()
        break;
      case 'search':
        filters.search = event.target.value
        break;
      default:
        break;
    }
    setFilters(_filters)
    getData(1, limit)
  }


  useEffect(() => {
    getData(page, 10, skip);
    getMarcas()
  }, [])

  const getData = (ppage, _limit) =>{
    setLoading(true);
    let _filter = '';
    if(filters.isSale !== 'all') _filter += '&is_sale='+filters.isSale;
    if(filters.isPublished !== 'all') _filter += '&published='+filters.isPublished;
    if(filters.estado !== 'all') _filter += '&status_im='+filters.estado;
    if(filters.search !== '') _filter += '&search='+filters.search;
    if(filters.make !== '') _filter += '&make='+filters.make;
    if(filters.year !== '') _filter += '&year='+filters.year;
    if(filters.model !== '') _filter += '&model='+filters.model;
    
    VehiculosService.getAll(ppage,_limit,skip,_filter).then(resp =>{
      let _data = resp.data;
      _data = _data.filter(x => x.isDeleted == false);

      setTotalRows(resp.total);
      setData(_data);
      setLoading(false);
    })
    .catch(error => console.log(error));
  }

  const handlePageChange = page => {
    getData(page, limit);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {  
    setLoading(true)
    setTimeout(() => {
      setLimit(newPerPage)
      getData(page, newPerPage);    
      setLoading(false)
    }, 2000);    
  };

  const sincronizar = async () => {
    try {
      setShowLoad(true);
  
      // Sincronizar los pasos secuencialmente
      await VehiculosService.updateNewCars();
      await VehiculosService.getIdsML();
      await VehiculosService.updateCarsML();
  
      // Opcional: Si necesitas ejecutar más pasos, descomenta aquí
      // await VehiculosService.getDescription();
  
      // Esperar un poco antes de finalizar
      await new Promise(resolve => setTimeout(resolve, 2000));
  
      setShowLoad(false);
      window.location.reload();
    } catch (error) {
      console.error("Error durante la sincronización:", error);
      setShowLoad(false); // Asegúrate de ocultar el loader incluso si hay un error
    }
  };

  const getMarcas = () =>{
    MakeService.getAll().then(resp => {
      setMakes(resp.data)
    })
  }

  const getYears = () =>{
    let makeId = makes.find(x => x.name === filters.make)
    setDisabledYear(!disabledYear)
    YearService.getYearByMake(makeId.id).then(resp =>{
      setYears(resp.data)
    })
  }

  const getModels = () =>{
    let yearId = years.find(x => x.name === filters.year)
    setDisabledModel(!disabledModel)
    ModelService.getModelsByYear(yearId.id).then(resp =>{
      setModels(resp.data)
    })
  }

  const verListado = () =>{
    navigate('/vehiculos/lista')
  }
  return(
    <>
      <CRow>
        <CCol xs={12}>
            <ToastContainer />
            <div className='fButton'>
              <Puff
                    height="45"
                    width="45"
                    radius={1}
                    color="#c14717"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={ showLoad }
                />
                {/* <CButton onClick={sincronizar}>Sincronizar</CButton> */}
                <CButton onClick={verListado} className='ms-2 btn-secondary'>Ver listado</CButton>
                <Link to={ '/vehiculos/crear' } className='ms-2 btn btn-info'> Crear </Link>
            </div>
        </CCol>
      </CRow>
      <CRow className='justify-content-end'>
        <CCol sm="12" md="2">
          <div className='form-group'>
            <label style={{ fontSize: '12px'}}>Marca</label>
            <select className='form-select form-select-sm' onChange={saveField} name='selectMake' defaultValue={ filters.make }>
              <option value="">Seleccione la marca</option>
              {
                makes.length > 0 ? makes.map((item) => (
                  <option value={item.name} key={'make_'+item.id}>{ item.name }</option>
                )): ''
              }
            </select>
          </div>
        </CCol>
        <CCol sm="12" md="2">
          <div className='form-group'>
            <label style={{ fontSize: '12px'}}>Año</label>
            <select className='form-select form-select-sm' onChange={saveField} name='selectYear' defaultValue={ filters.year } disabled={disabledYear}>
              <option value="">Seleccione el año</option>
              {
                years.length > 0 ? years.map((item) => (
                  <option value={item.name} key={'year_'+item.id}>{ item.name }</option>
                )): ''
              }
            </select>
          </div>
        </CCol>
        <CCol sm="12" md="2">
          <div className='form-group'>
            <label style={{ fontSize: '12px'}}>Modelo</label>
            <select className='form-select form-select-sm' onChange={saveField} name='selectModel' defaultValue={ filters.model } disabled={disabledModel}>
              <option value="">Seleccione el Modelo</option>
              {
                models.length > 0 ? models.map((item) => (
                  <option value={item.name} key={'model_'+item.id}>{ item.name }</option>
                )): ''
              }
            </select>
          </div>
        </CCol>
        <CCol sm="12" md="2">
          <div className='form-group'>
            <label style={{ fontSize: '12px'}}>¿Publicado?</label>
            <select className='form-select form-select-sm' onChange={saveField} name='selectIsPublished' defaultValue={ filters.isPublished }>
              <option value="all">Seleccione</option>
              <option value={1}>Si</option>
              <option value={0}>No</option>
            </select>
          </div>
        </CCol>
        <CCol sm="12" md="2">
          <div className='form-group'>
            <label style={{ fontSize: '12px'}}>¿Vendido?</label>
            <select className='form-select form-select-sm' onChange={saveField} name='selectIsSale' defaultValue={ filters.isSale }>
              <option value="all">Seleccione</option>
              <option value={1}>Si</option>
              <option value={0}>No</option>
            </select>
          </div>
        </CCol> 
        <CCol sm="12" md="2">
        <div className='form-group'>
          <label style={{ fontSize: '12px'}}>Buscar</label>
          <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <input type="search" className='form-control form-control-sm' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
            </span>
            </div>
        </CCol>
      </CRow> 
      <CRow>
        <CCol>
          <DataTable
            noDataComponent={"No se han encontrado resultados"}
            columns={ columns }
            data={data}
            progressPending={loading}
            pagination 
            paginationServer
            responsive             
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default Vehiculos
